import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/10DLC": [3],
		"/10DLC/[reg_kind]": [~4],
		"/10DLC/[reg_kind]/create": [~5],
		"/10DLC/[reg_kind]/modify": [~6],
		"/10DLC/[reg_kind]/view": [~7],
		"/accept-invite": [~8],
		"/add-ons": [9],
		"/add-ons/[add_on]": [~10],
		"/admin": [11],
		"/admin/10DLC/registrations": [~12],
		"/admin/10DLC/registrations/view": [~13],
		"/admin/account-activity": [14],
		"/admin/credit-overview": [~15],
		"/admin/credit-purchases": [~16],
		"/admin/credit-uses": [~17],
		"/admin/orgs": [~18],
		"/admin/orgs/view": [~19],
		"/admin/tidying": [~20],
		"/admin/users": [~21],
		"/api-keys": [~22],
		"/api-keys/permissions": [~23],
		"/api-keys/usage": [~24],
		"/campaigns": [25],
		"/campaigns/copy": [~26],
		"/campaigns/create": [~27],
		"/campaigns/modify": [~28],
		"/campaigns/templates": [29],
		"/campaigns/view": [~30],
		"/contacts": [~31],
		"/contacts/create": [32],
		"/contacts/dedupe": [33],
		"/contacts/edit": [~34],
		"/contacts/search": [~35],
		"/contacts/tag": [~36],
		"/contacts/upload": [37],
		"/contacts/validation": [~38],
		"/custom-fields": [39],
		"/custom-reply-words": [~40],
		"/email/bulk": [~41],
		"/email/templates": [~42],
		"/forgot-password": [43],
		"/get-credits": [~44],
		"/getting-started": [~45],
		"/integrations": [~46],
		"/integrations/GetResponse": [47],
		"/integrations/GetResponse/campaigns": [~48],
		"/integrations/GetResponse/campaigns/[name]": [~49],
		"/integrations/GetResponse/webhooks": [~50],
		"/integrations/Zapier": [51],
		"/pricing": [~52],
		"/profile": [~53],
		"/profile/permissions": [~54],
		"/reports/clicks": [55],
		"/reports/demographic": [56],
		"/reports/financial": [~57],
		"/reports/hooks": [58],
		"/reports/hooks/view": [~59],
		"/reports/queued-failed-sends": [60],
		"/reports/replies": [~61],
		"/reports/replies/view": [~62],
		"/reports/sends": [63],
		"/reports/sends/view": [~64],
		"/request-verification": [~65],
		"/reset-password": [66],
		"/sales": [67],
		"/sales/upload": [68],
		"/segments": [69],
		"/segments/modify": [70],
		"/send": [71],
		"/send/bulk": [72],
		"/send/campaign": [~73],
		"/signin": [~74],
		"/signup": [75],
		"/signup/demo": [76],
		"/signup/trade-show": [77],
		"/tags": [78],
		"/tags/modify": [79],
		"/test": [~80],
		"/unverified-email": [~81]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';